.form-notification .Toastify__toast-container--top-right {
  top: 70px;
}

.features-section .testimonial-tab-menu.nav-pills .nav-link.active,
.features-section .testimonial-tab-menu.nav-pills .show > .nav-link {
  border-color: #175cff !important;
  box-shadow: 0 20px 20px 0 rgba(2, 19, 79, 0.1);
  background-color: #175cff !important;
  transform: translateY(-3px);
}
