.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.introSection__carouselContainer {
  background-color: #0b163f !important;
}

.carousel-item {
  height: 660px;;
}

.carousel-item video {
  width: 100%;
  height: 56.25vw;
}

.caption-text {
  font-size: 1.125rem;
  font-weight: 400;
}

@media (min-width: 320px) and (max-width: 768px) {

  .carousel-indicators {
    bottom: -45px;
  }

  .carousel-caption {
    display: block;
    background-color: #0b163f !important;
    position: unset;
    font-size: 1.125rem;
  }

  .pt-100 {
    padding-top: 76px;
  }

  .carousel-item {
    height: unset;
  }

  .carousel-item video {
    width: auto;
  }
}
